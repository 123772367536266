import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

// Rich text rendering
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { ContentContainer } from "../components/Theme"
import { renderOption } from "../utils/contentful-options"

const BlogPostContentfulTemplate = ({ data, pageContext, location }) => {
  const post = data.contentfulPost
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const StyledImg = styled(Img)`
    max-height: 1200px;
    img {
      width: auto !important;
    }
  `
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.title} description={post.subtitle} />
      <ContentContainer>
        <article>
          <header>
            {post.image && (
              <StyledImg
                fluid={post.image && post.image.fluid}
                fadeIn
                draggable={false}
                objectFit="cover"
                objectPosition="50% 50%"
                onContextMenu={e => e.preventDefault()}
              />
            )}
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {post.title}
            </h1>
          </header>
          <section>
            {documentToReactComponents(post.content.json, renderOption)}
          </section>
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
            <Bio />
          </footer>
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={`/${previous.slug}`} rel="prev">
                  ← {previous.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`/${next.slug}`} rel="next">
                  {next.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </ContentContainer>
    </Layout>
  )
}

export default BlogPostContentfulTemplate

export const pageQuery = graphql`
  query ContentfulBlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulPost(slug: { eq: $slug }) {
      title
      subtitle
      image {
        fluid(quality: 75, maxWidth: 1140) {
          ...GatsbyContentfulFluid
        }
      }
      content {
        json
      }
    }
  }
`
